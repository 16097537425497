import React from "react"
import { Link } from "gatsby"
import NewsHeader from "../../components/blog/blogHeader"
import NewsFooter from "../../components/blog/blogFooter"
import Footer from "../../components/footer"
import SEO from "../../components/seo"

import arrow from "../../../assets/images/servicesPages/arrow-right-min.png"
import image1 from "../../../assets/images/blog/drupal_upgrate/image1.jpg"
import image2 from "../../../assets/images/blog/drupal_upgrate/image2.jpg"
import image3 from "../../../assets/images/blog/drupal_upgrate/image3.jpg"


export default () => 
<div>
    <SEO title={"Why You Should Upgrade to Drupal 9"} 
    description="Are you still wondering when and how you should upgrade your website to Drupal 9?"
    canonical={'https://icon-worldwide.com/blog/upgrade-drupal-9'}
    image={'https://icon-worldwide.com/social_images/drupal_upgrate.jpg'} />

    <NewsHeader/>

    <div id="single-news">
            <h1><span>Upgrade</span> from Drupal 8 to Drupal 9 </h1>
            <div id="title-underline"></div>
        <div id="single-news-texts">
            <img src={image1} alt="Upgrade from Drupal 8 to Drupal 9 " title="Upgrade from Drupal 8 to Drupal 9 " style={{width:'100%', display:'block', margin:'0 0 30px'}}/>
            <p>Over <strong><a href="https://www.drupal.com/showcases" target="_blank" rel="noopener noreferrer">1 million websites around the world</a></strong> run on Drupal, from high tech companies, to government, NGO’s, and higher education institutions. </p>
            <p>So now that Drupal 9 has been released, and Drupal 10 will be released - should you upgrade directly from Drupal 7 to Drupal 9, and bypass Drupal 8?  In most cases, the answer is yes.  This will also prepare your site for the later upgrade to Drupal 10,  and give your site  the maximum expected life.</p>
            <p>Alternately, you can upgrade to Drupal 8 first, but the Drupal 8 end of life is the same as Drupal 7, meaning you would eventually have to upgrade to Drupal 9 anyway.  The only reasons to first upgrade to Drupal 8 depends on your site requirements, and modules that are available in Drupal 8 but not yet in a Drupal 9 compatible release. </p>
            <p>Drupal 9 is identical to the final Drupal 8 release, but with deprecated code removed and third party dependencies updated. Upgrading to Drupal 8 first in some cases will make the eventual upgrade to Drupal 9 far easier. If you are unsure, it would be best to contact Drupal.org, or a trusted partner for a code review.</p>
            <p>Drupal 8 support will end in <a href="https://www.drupal.org/psa-2020-06-24" target="_blank" rel="noopener noreferrer">November 2021</a>, because of the Drupal 8 dependency  on Symfony 3, and <a href="https://symfony.com/roadmap/3.4" target="_blank" rel="noopener noreferrer">Symfony 3's end of life is November 2021</a>. </p>
            <p>Upgrading to Drupal 9 from 8 is simply a matter of running update.php as long as your codebase does not use deprecated APIs. There are <a href="https://www.drupal.org/docs/9/how-to-prepare-your-drupal-7-or-8-site-for-drupal-9/deprecation-checking-and-correction-tools" target="_blank" rel="noopener noreferrer">tools available</a> that will identify and fix deprecated APIs in your codebase</p>
            <p><strong>As Drupal 9 was released in June 2020</strong>: and both Drupal 7 and 8 are reaching end of life, its time to plan your <a href="https://www.drupal.org/about/9" target="_blank" rel="noopener noreferrer">transition to <strong>Drupal 9</strong></a>.   Some of the <strong>new functionalities</strong> in Drupal 9 are:</p>
            <ul>
                <li><strong>A simple set-up and migration process</strong></li>
                <li><strong>An enhanced user experience and an intuitive content management;</strong></li>
                <li><strong>A better accessibility;</strong></li>
                <li><strong>A focus on automatic updates</strong></li>
            </ul>
            <h2>Major benefits of upgrading your site to Drupal 9</h2>
            <img src={image2} alt="Major benefits of upgrading your site to Drupal 9 " title="Major benefits of upgrading your site to Drupal 9" style={{width:'100%', display:'block', margin:'0 0 30px'}}/>
            <h3>1. Enhanced performance and security</h3>
            <p><strong>Drupal 9 comes with even more powerful performance results</strong>, including:</p>
            <ul>
                <li><strong>Content authoring experience and multiple content workﬂows have been optimized, thanks to the <a href="https://www.tadigital.com/insights/perspectives/boosting-website-performance-bigpipe-drupal" target="_blank" rel="noopener noreferrer">BigPipe caching strategy</a></strong> that includes in-core page, block, and views caching, as well as an extended ‘Internal Dynamic Page Cache’ for caching anonymous users’ data;</li>
                <li><strong>Symfony 3 will be replaced with <a href="https://symfony.com/4" target="_blank" rel="noopener noreferrer">Symfony 4</a> or</strong> 5 (after November 2021) <strong>and an upgrade to <a href="https://twig.symfony.com/" target="_blank" rel="noopener noreferrer">Twig 2.0</a> has been already planned</strong>, in order to improve the <Link to="/blog/inhouse-vs-nearshoresoftware-development-team">developer experience</Link>;</li>
                <li><strong>The support for responsive images has been implemented</strong>, allowing mobiles to display the best image size, consume less data, and provide a faster <Link to="/services/website-development">website performance</Link></li>
                <li><strong>The multilingual capabilities of Drupal 8 have been further improved</strong> and don’t require a re-architecture during the update;</li>
                <li><strong>The content structure offers a wider range of fields</strong>, including phone, email, date, and time;</li>
                <li><strong>The support of <a href="https://memcached.org/" target="_blank" rel="noopener noreferrer">Memcached</a> and <a href="https://redis.io/" target="_blank" rel="noopener noreferrer">Redis</a></strong> will help speed up the rendering processes.</li>
            </ul>
            <p><strong>In terms of <Link to="/blog/security-checklist-for-developers">security</Link>, all the obsolete code and vulnerabilities have been removed in Drupal 9</strong>, resulting in fewer security problems and security updates, also for <Link to="/blog/zero-party-data-healthcare-industry">third-party dependencies</Link>.</p>
            <p>Yet, <strong>most of the code interfaces remained untouched</strong>, which means that the developers won’t have any problem when it comes to migration and support.</p>
            <h3>2. Easy upgrade process</h3>
            <p><strong>One of the main Drupal 9 benefits is its easy migration process</strong>. If migrating your website from Drupal 7 to 8 an entire rebuild is required, but <strong><a href="https://www.drupal.org/project/backward_compatibility#:~:text=Backward%20Compatibility%20allows%20you%20to,you%20tell%20it%20to%20do." target="_blank" rel="noopener noreferrer">Drupal 9 is backward compatible</a> and many Drupal 8 modules are compatible with Drupal 9</strong>.</p>
            <h3>3. Conversational UI and user-friendliness</h3>
            <p>Another Drupal 9 benefit, <strong>in terms of user interface</strong>, is its <strong>backend CMS</strong>, which offers:</p>
            <ul>
                <li><strong>An improved UI and Admin Navigation System</strong></li>
                <li><strong>Simpler contents, taxonomy, users, blocks, and themes management</strong></li>
                <li><strong>A <Link to="/services/mobile-development">mobile-ﬁrst approach</Link></strong></li>
                <li><strong>The ability to easily manage CMS updates</strong></li>
            </ul>
            <p>These updates make <strong>Drupal even more user-friendly</strong>, making it possible for marketers and developers to set it up easily. Usability improvements include:</p>
            <ul>
                <li><strong>The updated editorial workflows</strong></li>
                <li><strong>The updated Media Library</strong></li>
                <li><strong>The new admin interface and default theme</strong></li>
            </ul>
            <h3>4. Coding improvements</h3>
            <p>An additional advantage is the <strong>code clean-up</strong>, which improves the: </p>
            <ul>
                <li><strong>Optimized code structure;</strong></li>
                <li><strong>A cleaner codebase with a better templating process, thanks to TWIG template engine</strong>, which doesn’t allow PHP or other code in theme templates;</li>
                <li><strong>The <a href="https://www.drupal.org/docs/understanding-drupal/how-drupal-9-was-made-and-what-is-included/how-and-why-we-deprecated-code" target="_blank" rel="noopener noreferrer">removal of deprecations</a></strong>, as deprecated codes will no longer have any further support, in order to improve the website’s performance.</li>
            </ul>
            <img src={image3} alt="E-commerce empowerment" title="E-commerce empowerment" style={{width:'100%', display:'block', margin:'0 0 30px'}}/>
            <h3>5. E-commerce empowerment</h3>
            <p>E-commerce is front and center in <strong>Drupal 9, including some interesting modules for integration with e-commerce platforms like <a href="https://www.elasticpath.com/" target="_blank" rel="noopener noreferrer">Elastic Path</a>, <a href="https://www.shopify.com/" target="_blank" rel="noopener noreferrer">Shopify</a>, <Link to="/blog/magento-vs-woocommerce/">Magento</Link>, and <a href="https://www.bigcommerce.com/homepage/" target="_blank" rel="noopener noreferrer">BigCommerce</a></strong>, and the <Link to="/blog/top-ecommerce-best-practices">e-commerce sites</Link>’ products can be synced as Drupal entities to deliver content-driven commerce experiences.</p>
            <p>Moreover, the new release offers <strong>an <Link to="/blog/ecommerce-trends-2020">API-first architecture</Link>, including the <a href="https://jsonapi.org/" target="_blank" rel="noopener noreferrer">JSON:API</a>, which enables building robust decoupled and headless applications, easily integrating with <a href="https://www.contentintelligence.net/en/ci/dam-pim-mrm-the-differences" target="_blank" rel="noopener noreferrer">DAM and PIM</a></strong>, by optimizing key digital assets and product information.</p>
            <p>And, in this regard, <strong>in order to help companies deliver an excellent customer experience, it offers <a href="https://www.drupal.org/association/supporters/blog/implementation-guide-on-headless-and-decoupled-cms" target="_blank" rel="noopener noreferrer">Drupal Headless CMS</a> and Magento</strong> to build the powerful front-end of the website with JavaScript framework, enhancing <strong>front-end freedom and easier resourcing</strong>.</p>
            <h3>6. Data platform integration and personalization</h3>
            <p>Besides, the above-mentioned <strong>built-in JSON:API offers Drupal 9 a seamless integration with</strong>:</p>
            <ul>
                <li><strong><a href="https://www.acquia.com/products/marketing-cloud/customer-data-platform" target="_blank" rel="noopener noreferrer">Acquia CDP</a></strong></li>
                <li><strong>Any external analytics tool</strong></li>
                <li><strong>CDPs, DMPs, and CRM</strong></li>
            </ul>
            <p>This will help you <strong>deliver a consistent experience across all channels</strong>, offering considerable savings and secured integration.</p>
            <p>In conclusion, <strong>upgrading your website to Drupal 9 will make it more usable, accessible, <Link to="/blog/why-invest-in-nearshore-software-development/">inclusive, flexible, and scalable</Link></strong> than Drupal 8:</p>
            <ul>
                <li><strong>Easier for marketers to use</strong></li>
                <li><strong>Simpler for developers to maintain and upgrade</strong></li>
                <li><strong>More powerful, thanks to its headless and decoupled capabilities</strong></li>
            </ul>
            <p><strong>But What About Drupal 10?</strong><br/>
            Drupal 10 will be released in June 2022, in one year.</p>
            <p>Why is Drupal 10 being released in June 2022? It’s about security. Security bugs will no longer get fixed in Symfony 4 from November 2023, and Drupal 9 depends on Symfony 4. Drupal is giving site owners operating on Drupal 9 a year to move to Drupal 10, adopting Symfony 5 to ensure continued site security. </p>
            <p>Drupal has reassured site owners that the move from D9 to D10 will be as easy as the move from D8 to D9 was. This is because the same backwards compatibility that exists in D9 with D8 will exist in D10 for D9. </p>
            <p>Dries Buytaert, Project Lead at Drupal <strong><a href="https://dri.es/drupal-10-target-release-date-and-drupal-9-end-of-life" target="_blank" rel="noopener noreferrer">explains how it will work</a>:</strong> </p>
            <p><em>‘New functionality for Drupal 10 is actually added to Drupal 9 releases. This means module developers can start adopting any new APIs right away. </em></p>
            <p><em>Along the way, we deprecate old functionality but keep backwards compatibility. Once we are ready to release Drupal 10, we remove all deprecated code. </em></p>
            <p><em>Removing deprecated code breaks backwards compatibility, but because module developers had a chance to stay up to date with API changes, the upgrade to Drupal 10 should be easy.’</em></p>
            <p>It’s important to include these dates in your Drupal roadmap. You’ll want to note when Drupal 9’s end of life is — November 2023 — as this is when you’ll need to migrate to Drupal 10 for security reasons.</p>
        </div>


        <img src={arrow} id="single-news-horizontal-arrow" alt="ICON Worldwide arrow" title="ICON Worldwide arrow"/>
        <p><strong>If  you need some help with your website transition from Drupal 8 to Drupal 9?</strong></p>
        <h4><Link to="/contact-us" className="cta">CONTACT US</Link></h4>

            <NewsFooter previous="nearshoring-bulgaria" next="how-to-invest-in-bitcoin"/>
        
    </div>

    <Footer noScrollbar="true"/>
</div>